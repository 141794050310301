// extracted by mini-css-extract-plugin
export var bullet__p3HYv = "PlasmicPlan-module--bullet__p3HYv--JmcbE";
export var bullet__w51Xv = "PlasmicPlan-module--bullet__w51Xv--zdSZn";
export var bullet__yHop = "PlasmicPlan-module--bullet__yHop--sLjx0";
export var button__h6Qe = "PlasmicPlan-module--button__h6Qe--9q57U";
export var freeBox__ksne1 = "PlasmicPlan-module--freeBox__ksne1--q6ncF";
export var freeBox__ppPWg = "PlasmicPlan-module--freeBox__ppPWg--iooks";
export var freeBox__yxfGl = "PlasmicPlan-module--freeBox__yxfGl--fg8R4";
export var freeBox__zuy9V = "PlasmicPlan-module--freeBox__zuy9V--viDgR";
export var root = "PlasmicPlan-module--root--CHHJ+";
export var slotTargetName = "PlasmicPlan-module--slotTargetName--Eh4kH";
export var svg__b4RK = "PlasmicPlan-module--svg__b4RK--7l8Ky";
export var svg__d5XxA = "PlasmicPlan-module--svg__d5XxA--qOv75";
export var svg__gS89C = "PlasmicPlan-module--svg__gS89C--Y0y6U";
export var text___5N4Zq = "PlasmicPlan-module--text___5N4Zq---rOB9";