// extracted by mini-css-extract-plugin
export var bullet___1YLx = "PlasmicPricing-module--bullet___1YLx--HBKef";
export var bullet___4CVgm = "PlasmicPricing-module--bullet___4CVgm--Bjzzh";
export var bullet___4RUvB = "PlasmicPricing-module--bullet___4RUvB--jJ-Yb";
export var bullet___9VtxT = "PlasmicPricing-module--bullet___9VtxT--Xrkyu";
export var bullet__elGx4 = "PlasmicPricing-module--bullet__elGx4--Sow3d";
export var bullet__fuj6 = "PlasmicPricing-module--bullet__fuj6--LyyhI";
export var bullet__hJ0U = "PlasmicPricing-module--bullet__hJ0U--VHCTb";
export var bullet__jbkSb = "PlasmicPricing-module--bullet__jbkSb--y16aC";
export var bullet__qxKi6 = "PlasmicPricing-module--bullet__qxKi6--Oyw70";
export var bullet__sh1Wj = "PlasmicPricing-module--bullet__sh1Wj--p5bcY";
export var button__kk6Xb = "PlasmicPricing-module--button__kk6Xb--gYhB7";
export var button__vZtpB = "PlasmicPricing-module--button__vZtpB--0p1Fo";
export var button__wKS = "PlasmicPricing-module--button__wKS--YRR3c";
export var column__bQt0B = "PlasmicPricing-module--column__bQt0B--tCewv";
export var column__kvgLz = "PlasmicPricing-module--column__kvgLz--0nona";
export var column__oh07P = "PlasmicPricing-module--column__oh07P--oCPVi";
export var columns = "PlasmicPricing-module--columns--Hmjvj";
export var faqSection = "PlasmicPricing-module--faqSection--VphoN";
export var faq__c5MYx = "PlasmicPricing-module--faq__c5MYx--BZQ-R";
export var faq__mBjVx = "PlasmicPricing-module--faq__mBjVx--PYBoz";
export var footer = "PlasmicPricing-module--footer--jDrNw";
export var freeBox___0NZYb = "PlasmicPricing-module--freeBox___0NZYb--HCPnS";
export var freeBox__j70Tm = "PlasmicPricing-module--freeBox__j70Tm--g+Kt-";
export var freeBox__lt9B3 = "PlasmicPricing-module--freeBox__lt9B3--WHcJq";
export var freeBox__nFEww = "PlasmicPricing-module--freeBox__nFEww--ogESV";
export var header = "PlasmicPricing-module--header--S2TXl";
export var plan___2M0Xm = "PlasmicPricing-module--plan___2M0Xm--NrPsN";
export var plan___6WHg7 = "PlasmicPricing-module--plan___6WHg7--WKcPT";
export var plan__efhC = "PlasmicPricing-module--plan__efhC--BIqoU";
export var pricingSection = "PlasmicPricing-module--pricingSection--Tqbgl";
export var root = "PlasmicPricing-module--root--pYMNq";
export var svg___2KAms = "PlasmicPricing-module--svg___2KAms--1JA12";
export var svg__dd7Gt = "PlasmicPricing-module--svg__dd7Gt--gdKO2";
export var svg__dm3IZ = "PlasmicPricing-module--svg__dm3IZ--eZ+8i";
export var svg__frGUe = "PlasmicPricing-module--svg__frGUe--4ntAw";
export var svg__s5Lh = "PlasmicPricing-module--svg__s5Lh--JXIsJ";
export var svg__sbKTv = "PlasmicPricing-module--svg__sbKTv--vMyag";
export var text__bycCc = "PlasmicPricing-module--text__bycCc--OJA6b";
export var text__j8FvC = "PlasmicPricing-module--text__j8FvC--4sM-E";
export var text__uKqXy = "PlasmicPricing-module--text__uKqXy--8zG73";